/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
  Suspense,
  useEffect,
  useState
} from 'react';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';

import PublicRoute from './components/route/PublicRoute';
import LoadingIndicator from './components/LoadingIndicator';
import {
  PrivateRoutes,
  PublicRoutes
} from './config/routes';
import PrivateRoute from './components/route/PrivateRoute';
import theme from './styles/theme';
import CreateEntryLayout from './components/layout/CreateEntryLayout';
import { EntryContextProvider } from './contexts/EntryContext';
import { AuthClientContext } from './reactShell';
import { survivorClient } from './service/backend';
import EditEntry from './containers/EditEntry';
import { SidebarContextProvider } from './contexts/SidebarContext';
import OrfEditLayout from './components/layout/OrfEditLayout';
import { OrfContextProvider } from './contexts/OrfContext';
import WithIdleTimer from './components/WithIdleTimer';
import { AppContextProvider } from './contexts/AppContext';
import EditEntryLayout from './components/layout/EditEntryLayout';
import PublicLayout from './components/layout/PublicLayout';

import Login from './containers/Login';
import ResetPassword from './containers/ResetPassword';
import ResetNew from './containers/ResetNew';
import SignUp from './containers/SignUp';
import SignUpCredential from './containers/SignUpCredentials';
import SignUpToken from './containers/Token';
import NotFoundPage from './containers/NotFoundPage';
import CreateWhere from './containers/CreateWhere';
import CreateWho from './containers/CreateWho';
import ContactInfo from './containers/ContactInfo';
import SubmitForm from './containers/SubmitForm';
import AccountSettings from './containers/AccountSettings';
import OrfIntro from './containers/OrfIntro';
import OrfWhen from './containers/OrfWhen';
import OrfWhere from './containers/OrfWhere';
import OrfKnow from './containers/OrfKnow';
import OrfConsent from './containers/OrfConsent';
import OrfIncidents from './containers/OrfIncidents';
import OrfOffenders from './containers/OrfOffenders';
import OrfEvidence from './containers/OrfEvidence';
import OrfReview from './containers/OrfReview';
import { EmergencyExitButton } from './components/EmergencyExitButton';
import { OrfPdf } from './containers/OrfPdf';
import { Demographics } from './containers/Demographics';
import { IncidentLogPdfContextProvider } from './containers/IncidentLogPdf/IncidentLogPdfContext';
import Feedback from './containers/Feedback';
import ConsentDashboard from './containers/ConsentDashboard';
import SignUpForeignEdu from './containers/SignUpForeignEdu';
import CreateIncidentLogLayout from './components/layout/CreateIncidentLogLayout';
import { IncidentLogContextProvider } from './contexts/IncidentLogContext';
import IncidentLogPage from './containers/IncidentLog/IncidentLogPage';
import { IncidentLogSummaryPage } from './containers/IncidentLog/IncidentLogSummaryPage';
import { IncidentLogDashboard } from './containers/IncidentLogDashboard';
import MainLayout from './components/layout/MainLayout';
import MatchingSystemDashboard from './containers/MatchingSystem';
import { MainDashboard } from './containers/MainDashboard';
import { MainSidebarContextProvider } from './contexts/MainSidebarContext';
import { IncidentLogPdf } from './containers/IncidentLogPdf/IncidentLogPdf';
import { AnswerSecurityQuestions } from './containers/AnswerSecurityQuestions';
import { RecoverAccountToken } from './containers/RecoverAccountToken';
import { RecoveryEmailEntry } from './containers/RecoveryEmailEntry';
import { SignupCredentialsToken } from './containers/SignupCredentialsToken';
import { RecoverySetup } from './containers/RecoverySetup';
import { VerifyRecoveryEmailToken } from './containers/VerifyRecoveryEmailToken';
import { VerifyRecoveryEmail } from './containers/VerifyRecoveryEmail';
import { RequestAccountRecovery } from './containers/RequestAccountRecovery';
import { Resources } from './containers/Resources';
import SelectSocialMedia from './containers/CreateWho/SelectSocialMedia';
import SocialAccounts from './containers/CreateWho/SocialAccounts';
import RedirectToLogin from './components/RedirectToLogin';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const App: React.FC = () => {
  const [envVars, setEnvVars] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    const getEnvVars = async () => {
      setEnvVars(await survivorClient.getEnvironmentVariables());
    };

    if (!envVars) {
      void getEnvVars();
    }
  }, []);

  return (
    <AuthClientContext.Provider value={survivorClient}>
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <SidebarContextProvider>
            <MainSidebarContextProvider>
              <ChakraProvider theme={theme}>
                <Suspense fallback={<LoadingIndicator />}>
                  <EmergencyExitButton>
                    <Router>
                      <IncidentLogContextProvider>
                        <OrfContextProvider>
                          <IncidentLogPdfContextProvider>
                            <EntryContextProvider>
                              <WithIdleTimer>
                                <Routes>
                                  <Route element={<PublicLayout />}>
                                    <Route element={<PublicRoute />}>
                                      <Route
                                        path={PublicRoutes.LOGIN}
                                        element={<Login />}
                                      />
                                      <Route
                                        path={PublicRoutes.RESET_PASSWORD}
                                        element={<ResetPassword />}
                                      />
                                      <Route
                                        path={PublicRoutes.RESET_PASSWORD_NEW}
                                        element={<ResetNew />}
                                      />
                                      {/* <Route
                                        path={PublicRoutes.SIGN_UP}
                                        element={<SignUp />}
                                      /> */}
                                      <Route
                                        path={PublicRoutes.SIGN_UP_FOREIGN_EDU}
                                        element={<SignUpForeignEdu />}
                                      />
                                      <Route
                                        path={PublicRoutes.SIGN_UP_CREDENTIAL}
                                        element={<SignUpCredential />}
                                      />
                                      <Route
                                        path={PublicRoutes.SIGN_UP_TOKEN}
                                        element={<SignUpToken />}
                                      />
                                      <Route
                                        path={PublicRoutes.FEEDBACK}
                                        element={<Feedback />}
                                      />
                                      <Route
                                        path={PublicRoutes.RECOVERY_EMAIL_ADDRESS}
                                        element={<RecoveryEmailEntry />}
                                      />
                                      <Route
                                        path={PublicRoutes.SIGN_UP_CREDENTIAL_TOKEN}
                                        element={<SignupCredentialsToken />}
                                      />
                                      <Route
                                        path={PublicRoutes.RECOVER_ACCOUNT_TOKEN}
                                        element={<RecoverAccountToken />}
                                      />
                                      <Route
                                        path={PublicRoutes.ANSWER_SECURITY_QUESTIONS}
                                        element={<AnswerSecurityQuestions />}
                                      />
                                      <Route
                                        path={PublicRoutes.VERIFY_RECOVERY_EMAIL_TOKEN}
                                        element={<VerifyRecoveryEmailToken />}
                                      />
                                      <Route
                                        path={PublicRoutes.VERIFY_RECOVERY_EMAIL}
                                        element={<VerifyRecoveryEmail />}
                                      />
                                      <Route
                                        path={PublicRoutes.REQUEST_ACCOUNT_RECOVERY}
                                        element={<RequestAccountRecovery />}
                                      />
                                    </Route>
                                  </Route>
                                  <Route element={<PrivateRoute />}>
                                    <Route
                                      path={PrivateRoutes.DEMOGRAPHICS}
                                      element={<Demographics />}
                                    />
                                    <Route
                                      path={PrivateRoutes.RECOVERY_SETUP}
                                      element={<RecoverySetup />}
                                    />
                                    <Route element={<MainLayout />}>
                                      <Route
                                        path={PrivateRoutes.DASHBOARD}
                                        element={<MainDashboard />}
                                      />
                                      {/* <Route
                                        path={PrivateRoutes.MATCHING_SYSTEM}
                                        element={<MatchingSystemDashboard />}
                                      /> */}
                                      <Route
                                        path={PrivateRoutes.ILOG}
                                        element={<IncidentLogDashboard />}
                                      />
                                      <Route
                                        path={PrivateRoutes.CONSENT_SETTINGS}
                                        element={<ConsentDashboard />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ACCOUNT}
                                        element={<AccountSettings />}
                                      />
                                      <Route
                                        path={PrivateRoutes.CONTACT_US}
                                        element={<Feedback />}
                                      />
                                      <Route
                                        path={PrivateRoutes.RESOURCES}
                                        element={<Resources />}
                                      />
                                    </Route>
                                    <Route element={<CreateEntryLayout />}>
                                      <Route
                                        path={PrivateRoutes.CREATE_WHERE}
                                        element={<CreateWhere />}
                                      />
                                      <Route
                                        path={PrivateRoutes.CREATE_WHO}
                                        element={<CreateWho />}
                                      >
                                        <Route
                                          path={PrivateRoutes.CREATE_WHO}
                                          element={<SelectSocialMedia />}
                                        />
                                        <Route
                                          path={PrivateRoutes.CREATE_WHO_SOCIAL}
                                          element={<SocialAccounts />}
                                        />
                                      </Route>
                                      <Route
                                        path={PrivateRoutes.CREATE_CONTACT}
                                        element={<ContactInfo />}
                                      />
                                      <Route
                                        path={PrivateRoutes.CREATE_SUBMIT}
                                        element={<SubmitForm />}
                                      />
                                    </Route>
                                    <Route element={<EditEntryLayout />}>
                                      <Route
                                        path={PrivateRoutes.ENTRIES_EDIT}
                                        element={<EditEntry />}
                                      />
                                    </Route>
                                    <Route element={<CreateIncidentLogLayout />}>
                                      <Route
                                        path={PrivateRoutes.ILOG_WHO_WHAT}
                                        element={<IncidentLogPage stepName="whoWhat" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_WHEN}
                                        element={<IncidentLogPage stepName="when" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_WHERE}
                                        element={<IncidentLogPage stepName="where" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_PEOPLE}
                                        element={<IncidentLogPage stepName="people" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_CONSENT}
                                        element={<IncidentLogPage stepName="consent" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_INCIDENT}
                                        element={<IncidentLogPage stepName="incident" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_OFFENDERS}
                                        element={<IncidentLogPage stepName="offenders" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_EVIDENCE}
                                        element={<IncidentLogPage stepName="evidence" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_NOTES}
                                        element={<IncidentLogPage stepName="notes" />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_SUMMARY}
                                        element={<IncidentLogSummaryPage />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ILOG_PDF_DOWNLOAD}
                                        element={<IncidentLogPdf />}
                                      />
                                    </Route>
                                    <Route element={<OrfEditLayout />}>
                                      <Route
                                        path={PrivateRoutes.ORF_INTRO}
                                        element={<OrfIntro />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_WHEN}
                                        element={<OrfWhen />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_WHERE}
                                        element={<OrfWhere />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_KNOW}
                                        element={<OrfKnow />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_CONSENT}
                                        element={<OrfConsent />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_INCIDENTS}
                                        element={<OrfIncidents />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_OFFENDERS}
                                        element={<OrfOffenders />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_EVIDENCE}
                                        element={<OrfEvidence />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_REVIEW}
                                        element={<OrfReview />}
                                      />
                                      <Route
                                        path={PrivateRoutes.ORF_PDF_DOWNLOAD}
                                        element={<OrfPdf />}
                                      />
                                    </Route>
                                  </Route>
                                  <Route
                                    path="/"
                                    element={<RedirectToLogin />}
                                  />
                                  <Route path="*" element={<NotFoundPage />} />
                                </Routes>
                              </WithIdleTimer>
                            </EntryContextProvider>
                          </IncidentLogPdfContextProvider>
                        </OrfContextProvider>
                      </IncidentLogContextProvider>
                    </Router>
                  </EmergencyExitButton>
                </Suspense>
              </ChakraProvider>
            </MainSidebarContextProvider>
          </SidebarContextProvider>
        </QueryClientProvider>
      </AppContextProvider>
    </AuthClientContext.Provider>
  );
};
