/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.28.1
 * source: token.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export class GetRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        operation?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("operation" in data && data.operation != undefined) {
                this.operation = data.operation;
            }
        }
    }
    get operation() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set operation(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        operation?: string;
    }): GetRequest {
        const message = new GetRequest({});
        if (data.operation != null) {
            message.operation = data.operation;
        }
        return message;
    }
    toObject() {
        const data: {
            operation?: string;
        } = {};
        if (this.operation != null) {
            data.operation = this.operation;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.operation.length)
            writer.writeString(1, this.operation);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.operation = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GetRequest {
        return GetRequest.deserialize(bytes);
    }
}
export class GetResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        token?: string;
    }): GetResponse {
        const message = new GetResponse({});
        if (data.token != null) {
            message.token = data.token;
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.token.length)
            writer.writeString(1, this.token);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GetResponse {
        return GetResponse.deserialize(bytes);
    }
}
export abstract class UnimplementedTokenServiceService {
    static definition = {
        get_token: {
            path: "/org.callisto.v2.token.TokenService/get_token",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: GetRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => GetRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: GetResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => GetResponse.deserialize(new Uint8Array(bytes))
        }
    };
    [method: string]: grpc_1.UntypedHandleCall;
    abstract get_token(call: grpc_1.ServerUnaryCall<GetRequest, GetResponse>, callback: grpc_1.sendUnaryData<GetResponse>): void;
}
export class TokenServiceClient {
    private _address: string;
    private _client: grpc_web_1.GrpcWebClientBase;
    constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
        if (!options)
            options = {};
        options.format = options.format || "text";
        this._address = address;
        this._client = new grpc_web_1.GrpcWebClientBase(options);
    }
    private static get_token = new grpc_web_1.MethodDescriptor<GetRequest, GetResponse>("/org.callisto.v2.token.TokenService/get_token", grpc_web_1.MethodType.UNARY, GetRequest, GetResponse, (message: GetRequest) => message.serialize(), GetResponse.deserialize);
    get_token(message: GetRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<GetRequest, GetResponse>(this._address + "/org.callisto.v2.token.TokenService/get_token", message, metadata || {}, TokenServiceClient.get_token);
    }
}
